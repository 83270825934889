<template>
  <div class="order_page">
    <!-- 订单精准查询 -->
    <div
      style="
        box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
        background: #fff;
        padding: 30px;
        height: 100%;
        box-sizing: border-box;
      "
    >
      <div class="page_title">{{ language.Cashwithdrawaldetails }}</div>
      <div style="display: flex; margin-top: 30px">
        <!-- 提現訂單 -->
        <div class="input_box input_box3">
          <el-input
            v-model="Order_Number"
            :placeholder="language.Merchantordernumber"
          ></el-input>
        </div>
        <!-- EPAY订单号 -->
        <div class="input_box">
          <el-input
            v-model="EPAYOrder_Number"
            :placeholder="'EPAY ' + language.orderNumber"
          ></el-input>
        </div>
        <!-- 交易时间 -->
        <div class="input_box">
          <el-date-picker
            v-model="create_time"
            type="daterange"
            :range-separator="language.to"
            :start-placeholder="language.Start_Date"
            :end-placeholder="language.End_Date"
          >
          </el-date-picker>
        </div>
        <!-- 币种 -->
        <div class="input_box input_box3">
          <el-select v-model="Currency" :placeholder="language.Currency">
            <el-option
              v-for="item in options2"
              :key="item.type_name"
              :label="item.type_name"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 提现状态 -->
        <div class="input_box input_box3">
          <el-select
            v-model="Cashwithdrawalstatus"
            :placeholder="language.Cashwithdrawalstatus"
          >
            <el-option
              v-for="item in options3"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <!-- 搜索 -->
        <div class="input_box">
          <el-button type="primary" id="SearchBtn" @click="SearchBtn()">{{
            language.Search
          }}</el-button>
        </div>
        <!-- 重置 -->
        <div class="input_box">
          <el-button type="danger" id="ResetBtn" @click="ResetBtn()">{{
            language.Reset
          }}</el-button>
        </div>
        <!-- 打印 -->
        <div
          class="input_box Export_box"
          :style="Exporttype == 2 ? 'min-width:200px' : ''"
        >
          <el-button v-if="Exporttype == 1" type="success" @click="ExportBnt()">{{
            language.Export
          }}</el-button>
          <el-progress
            v-if="Exporttype == 2"
            style="width: 100%"
            :percentage="percentage"
            :show-text="false"
          ></el-progress>
          <download-excel
            v-if="Exporttype == 3"
            class="export-excel-wrapper"
            :data="json_table"
            :fields="json_fields"
            type="txt"
            :name="ordername + '.xls'"
          >
            <el-button type="success">{{ language.Download_Bill }}</el-button>
          </download-excel>
        </div>
      </div>
      <div>
        <div class="search_last_box">
          <!-- 已支付金额 -->
          <div v-if="showCurrency">
            <div>{{ language.Withdrawnamount }}</div>
            <div>{{ yesTransferValue }}{{ showCurrency }}</div>
          </div>
          <!-- 未支付金额 -->
          <!-- <div>
            <div>{{ language.Unpaidamount }}</div>
            <div>{{ notPayValue }}{{ currency }}</div>
          </div> -->
        </div>
        <div style="padding: 10px 10px 10px 0">
          <div
            style="
              width: 26px;
              height: 26px;
              display: flex;
              align-items: center;
              justify-content: center;
              background: #2c3e50;
              cursor: pointer;
            "
            @click="init()"
          >
            <img style="width: 16px" src="../../static/Refresh.png" alt="" />
          </div>
        </div>
        <el-table
          :data="tableData"
          border
          style="width: 100%"
          stripe
          v-loading="loading"
          :header-cell-style="{ background: '#fafafa' }"
        >
          <!-- 提现订单 -->
          <el-table-column
            prop="mch_order_no"
            :label="language.Merchantordernumber"
            min-width="10%"
          >
          </el-table-column>
          <el-table-column
            prop="my_order_no"
            :label="'EPAY ' + language.orderNumber"
            min-width="10%"
          >
          </el-table-column>
          <!-- 主网络 -->
          <!-- <el-table-column
            prop="appid"
            :label="language.Apply_No + '/appId'"
            min-width="10%"
          >
          </el-table-column> -->
          <!-- 姓名 -->
          <el-table-column prop="account_name" :label="language.name" min-width="10%">
          </el-table-column>
          <!-- 提现地址 -->
          <el-table-column :label="language.Addressdepositbank" min-width="10%">
            <template slot-scope="scope">
              <!-- <span v-if="scope.row.transfer_from_id == 6">{{
                scope.row.account_no
              }}</span> -->
              <span v-if="!scope.row.wallet_address">{{ scope.row.bank_code }}</span>
              <span v-if="scope.row.wallet_address">{{ scope.row.wallet_address }}</span>
            </template>
          </el-table-column>
          <el-table-column :label="language.TransactionChannel" min-width="10%">
            <template slot-scope="scope">
              <div v-for="(item, index) in Paymentchannellist" :key="index">
                <span
                  v-if="item.value == scope.row.transfer_from_id && item.value != -1"
                  >{{ item.label }}</span
                >
              </div>
            </template>
          </el-table-column>
          <!-- 币种 -->
          <el-table-column prop="currency" :label="language.Currency" min-width="10%">
          </el-table-column>
          <!-- 提现金额 -->
          <el-table-column :label="language.Cashwithdrawalamount" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.money }} </span>
              <span>{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 提现手续费 -->
          <el-table-column :label="language.Withdrawalhandlingfee" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.service_fee }} </span>
              <span>{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 商户结算金额 -->
          <el-table-column :label="language.Merchantsettlementamount" min-width="10%">
            <template slot-scope="scope">
              <span>{{ scope.row.settle_amount }} </span>
              <span>{{ scope.row.currency }}</span>
            </template>
          </el-table-column>
          <!-- 申请时间 -->
          <el-table-column
            prop="create_time"
            :label="language.Applicationtime"
            min-width="10%"
          >
          </el-table-column>
          <!-- 转账时间 -->
          <el-table-column :label="language.Transfertime" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.transfer_server_time">{{
                $getNowFormatDate.chagetime(scope.row.transfer_server_time)
              }}</span>
              <span v-else></span>
            </template>
          </el-table-column>
          <!-- 提现状态 -->
          <el-table-column :label="language.State" min-width="10%">
            <template slot-scope="scope">
              <!-- 待审核 -->
              <span v-if="scope.row.transfer_state == 1">{{
                language.Tobereviewed
              }}</span>
              <!-- 已审核待转款 -->
              <span style="color: #dd9410" v-if="scope.row.transfer_state == 2">{{
                language.Approvedpendingtransfer
              }}</span>
              <!-- 已拒绝 -->
              <span style="color: red" v-if="scope.row.transfer_state == 3">{{
                language.Rejected
              }}</span>
              <span style="color: #009b05" v-if="scope.row.transfer_state == 4">{{
                language.Completed
              }}</span>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column :label="language.Operation" min-width="10%">
            <!-- pay_state -->
            <template slot-scope="scope">
              <el-button size="mini" type="primary" @click="Details(scope.row)">{{
                language.Details
              }}</el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column :label="language.callbacknotification" min-width="10%">
            <template slot-scope="scope">
              <span
                style="color: #009b05"
                v-if="scope.row.is_notify && scope.row.transfer_state > 1"
                >{{ language.success }}</span
              >
              <span
                style="color: red"
                v-if="!scope.row.is_notify && scope.row.transfer_state > 1"
                >{{ language.bedefeated }}</span
              >
              <span v-if="scope.row.transfer_state == 1">/</span>
            </template>
          </el-table-column> -->
          <!-- 备注remarks -->
          <!-- <el-table-column prop="result_info" :label="language.remarks" min-width="10%">
            <template slot-scope="scope">
              <span v-if="scope.row.result_info">
                {{ scope.row.result_info }}
              </span>
              <span v-else> / </span>
            </template>
          </el-table-column> -->
        </el-table>
      </div>
      <el-dialog
        :title="language.Details"
        :visible.sync="DetailsdialogVisible"
        width="60%"
      >
        <!-- 创建时间 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Creation_time }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.create_time" disabled></el-input>
          </div>
        </div>
        <!-- 转账时间 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Transfertime }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.transfer_server_time" disabled></el-input>
          </div>
        </div>
        <!-- 状态 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.State }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.transfer_state2" disabled></el-input>
          </div>
        </div>
        <!-- 商户订单号 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Merchantordernumber }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.mch_order_no" disabled></el-input>
          </div>
        </div>
        <!-- EPAY订单号 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            EPAY {{ language.orderNumber }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.my_order_no" disabled></el-input>
          </div>
        </div>
        <!-- 应用编号appid -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Apply_No }}/AppId:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.appid" disabled></el-input>
          </div>
        </div>
        <!-- 交易渠道 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.TransactionChannel }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.transfer_from" disabled></el-input>
          </div>
        </div>
        <!-- 币种 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Currency }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.currency" disabled></el-input>
          </div>
        </div>
        <!-- 姓名 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.name }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.account_name" disabled></el-input>
          </div>
        </div>
        <!-- 地址 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Addressdepositbank }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.Addressdepositbank" disabled></el-input>
          </div>
        </div>
        <!-- 订单金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Cashwithdrawalamount }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.money" disabled></el-input>
          </div>
        </div>
        <!-- 提现手续费 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Withdrawalhandlingfee }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.service_fee" disabled></el-input>
          </div>
        </div>
        <!-- 商户结算金额 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.Merchantsettlementamount }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.settle_amount" disabled></el-input>
          </div>
        </div>
        <!-- USDT地址 -->
        <div class="msgbox" v-if="msg.currency == 'USDT'">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            USDT {{ language.Address }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.wallet_address" disabled></el-input>
          </div>
        </div>
        <!-- USDT协定 -->
        <div class="msgbox" v-if="msg.currency == 'USDT'">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            USDT {{ language.agreement }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.passage_id" disabled></el-input>
          </div>
        </div>
        <!-- 回档通知 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.callbacknotification }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.callbacknotification" disabled></el-input>
          </div>
        </div>
        <!-- 备注 -->
        <div class="msgbox">
          <div :style="languagetype == 'EN' ? 'width:205px' : 'width:110px'">
            {{ language.remarks }}:
          </div>
          <div style="flex: 1">
            <el-input v-model="msg.result_info" disabled></el-input>
          </div>
        </div>
      </el-dialog>
      <div>
        <!-- 分页 -->
        <div class="paging">
          <el-pagination
            v-loading="loading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            current-page.sync="5"
            :page-sizes="[10, 15, 20, 25, 30]"
            :page-size="limit"
            layout="sizes, prev, pager, next"
            :total="pages"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import payment from "../../common/json/payment.json";
import { transfer } from "../../api/index.js";
export default {
  data() {
    return {
      languagetype: "",
      title: "",
      loading: false,
      language: "",
      DetailsdialogVisible: false,
      showCurrency: "",
      tableData: [],
      json_fields: {
        // "名称": "name",//常规字段
        // "Telephone": "phone.mobile", //支持嵌套属性
      },
      json_table: [],
      json_meta: [
        [
          {
            " key ": " charset ",
            " value ": " utf- 8 ",
          },
        ],
      ],
      page: 1,
      limit: 15,
      pages: null,
      Order_Number: "", //订单号
      EPAYOrder_Number: "", //epay订单号
      appid: "", //应用编号
      create_time: null, //起止时间
      searchtime: "", //后台需要的时间
      showlimit: 10,
      Exporttype: 1,
      Exportpage: 0, //导出页数
      Exportpageing: 0, //已导出的页数
      Exporttrue: false,
      percentage: 0, //到处百分百
      Exportlimit: 100,
      ordername: "Order",
      options3: [
        {
          //提现状态
          value: "-1",
          label: "", //全部
        },
        {
          value: "1",
          label: "", //审核中
        },
        {
          value: "2",
          label: "", //已提现
        },
        {
          value: "3",
          label: "", //已拒绝
        },
        {
          value: "4",
          label: "", //已完成
        },
      ],
      options2: [
        {
          type_name: "",
          value: "",
        },
      ],
      Cashwithdrawalstatus: "", //提现状态
      dialogVisible: false,
      lookovertype: null,
      Reasonforrejection: "", //拒绝原因
      is_risk: "", //是否是风险账户
      state: "", //状态
      update_time: "", //更新时间
      Applicationtime: "", //申请时间
      Servicerate: "", //费率
      Withdrawalservicefee: "", //服务费
      mbWithdrawalmethod: "", //提现方式
      ordernumber: "", //订单号
      Currency: "", //币种筛选
      Paymentchannellist: [], //渠道
      userinfo: "",
      msg: "", //订单详情
      yesTransferValue: "",
    };
  },
  created() {
    this.Paymentchannellist = [...this.Paymentchannellist, ...payment.Paymentmethod];
    this.options2 = [...this.options2, ...payment.otherCurrencylist];
    this.init();
    this.userinfo = JSON.parse(localStorage.getItem("userinfo"));
    if (this.userinfo.currency != "all") {
      for (let i = this.options2.length - 1; i >= 0; i--) {
        if (this.options2[i].value != this.userinfo.currency) {
          this.options2.splice(i, 1);
        }
      }
    }
    if (this.options2[0]) {
      this.Currency = this.options2[0];
    }
    this.changelanguage();
  },
  watch: {
    "$store.state.language"() {
      this.changelanguage();
    },
    Exporttrue() {
      if (this.Exporttrue && this.Exporttype == 2) {
        if (this.Exportpageing < this.Exportpage) {
          this.Exportpageing = this.Exportpageing + 1;
          this.Exporttrue = false;
          this.forExport();
        }
      }
    },
  },

  methods: {
    // 获取列表
    init() {
      this.tableData = [];
      this.loading = true;
      let data = {
        page: this.page,
        limit: this.limit,
      };
      if (this.Order_Number) {
        data.mchOrder = this.Order_Number;
      }
      if (this.create_time) {
        data.datetime = this.searchtime;
      }
      if (this.appid) {
        data.appid = this.appid;
      }
      if (this.Cashwithdrawalstatus > -1) {
        data.transferState = this.Cashwithdrawalstatus;
      }
      if (this.Currency.value != -1 && this.Currency.value) {
        data.currency = this.Currency.value;
      }
      if (this.EPAYOrder_Number) {
        data.myOrder = this.EPAYOrder_Number;
      }
      data.type = 0;
      this.loading = false;
      transfer({}, data).then((res) => {
        if (res.code == 1) {
          this.showCurrency = this.Currency.value;
          this.loading = false;
          this.tableData = res.data.list;
          this.pages = res.data.count;
          this.yesTransferValue = res.data.yesTransferValue;
        }
      });
    },
    Details(e) {
      this.DetailsdialogVisible = true;
      this.msg = JSON.parse(JSON.stringify(e));
      if (this.msg.transfer_server_time) {
        this.msg.transfer_server_time = this.$getNowFormatDate.chagetime(
          this.msg.transfer_server_time
        );
      }
      if (this.msg.transfer_state == 1) {
        this.msg.transfer_state2 = this.language.Tobereviewed;
      } else if (this.msg.transfer_state == 2) {
        this.msg.transfer_state2 = this.language.Approvedpendingtransfer;
      } else if (this.msg.transfer_state == 3) {
        this.msg.transfer_state2 = this.language.Rejected;
      } else if (this.msg.transfer_state == 4) {
        this.msg.transfer_state2 = this.language.Completed;
      }
      // 渠道名
      if (this.msg.transfer_from_id == "-1") {
        this.msg.transfer_from = "";
      } else {
        this.Paymentchannellist.map((item) => {
          if (item.value == this.msg.transfer_from_id) {
            this.msg.transfer_from = item.label;
          }
        });
      }
      if (this.msg.currency == "USDT") {
        this.msg.passage_id = this.msg.passage_id.split("-")[1];
      }

      if (this.msg.transfer_from_id == 6) {
        this.msg.Addressdepositbank = this.msg.account_no;
      } else if (this.msg.transfer_from_id != 6 && !this.msg.wallet_address) {
        this.msg.Addressdepositbank = this.msg.account_no;
      } else {
        this.msg.Addressdepositbank = this.msg.wallet_address;
      }
      if (this.msg.is_notify && this.msg.transfer_state == 4) {
        this.msg.callbacknotification = this.language.success;
      } else if (!this.msg.is_notify && this.msg.transfer_state == 4) {
        this.msg.callbacknotification = this.language.bedefeated;
      } else {
        this.msg.callbacknotification = "/";
      }
      if (!this.msg.result_info) {
        this.msg.result_info = "/";
      }
    },
    // 到处列表
    ExportBnt() {
      this.$message({
        message: this.language.Exportingpleasedonotjumptothepageorotheroperations,
        type: "warning",
      });
      this.Exportpageing = 1;
      this.Exporttype = 2;
      this.json_table = [];
      this.forExport();
    },
    // 查看
    lookover() {
      this.lookovertype = 1;
      this.dialogVisible = true;
    },
    // 不通过
    Fail(e) {
      this.id = e.id;
      this.lookovertype = 2;
      this.dialogVisible = true;
    },
    // 已转款
    Transferred(e) {
      this.id = e.id;
      this.$confirm(
        this.language.Confirmwhethertocontinuethetransfer,
        this.language.prompt,
        {
          confirmButtonText: this.language.determine,
          cancelButtonText: this.language.Cancel,
          type: "warning",
        }
      )
        .then(() => {
          // this.Transferredtrue();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.language.Cancel,
          });
        });
    },
    // 受理
    accept(e) {
      this.id = e.id;
      this.$confirm(this.language.Confirmacceptance, this.language.prompt, {
        confirmButtonText: this.language.determine,
        cancelButtonText: this.language.cancel,
        type: "warning",
      })
        .then(() => {
          this.canceltrue();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.language.Cancelacceptance,
          });
        });
    },
    // 循环到处
    forExport() {
      let data;
      data = {
        page: this.Exportpageing,
        limit: this.Exportlimit,
      };
      this.Exporttrue = false;
      if (this.Order_Number) {
        data.mchOrder = this.Order_Number;
      }
      if (this.create_time) {
        data.datetime = this.searchtime;
      }
      if (this.appid) {
        data.appid = this.appid;
      }
      if (this.Cashwithdrawalstatus) {
        data.transferState = this.Cashwithdrawalstatus;
      }
      if (this.Currency.value != -1 && this.Currency.value) {
        data.currency = this.Currency.value;
      }
      if (this.EPAYOrder_Number) {
        data.myOrder = this.EPAYOrder_Number;
      }
      transfer({}, data).then((res) => {
        if (res.code == 1) {
          if (res.data.count == 0) {
            this.Exporttype = 1;
            this.$message({
              message: this.language.Nodata,
              type: "warning",
            });
            return;
          }
          this.Exportpage = Math.ceil(res.data.count / this.Exportlimit);
          this.Exporttrue = true;
          this.percentage = (this.Exportpageing / this.Exportpage) * this.Exportlimit;
          for (let i = 0; i < res.data.list.length; i++) {
            res.data.list[i].appid = String(res.data.list[i].appid);
            if (res.data.list[i].transfer_state == 1) {
              res.data.list[i].transfer_state = this.language.Tobereviewed;
            } else if (res.data.list[i].transfer_state == 2) {
              res.data.list[i].transfer_state = this.language.Approvedpendingtransfer;
            } else if (res.data.list[i].transfer_state == 3) {
              res.data.list[i].transfer_state = this.language.Rejected;
            } else if (res.data.list[i].transfer_state == 4) {
              res.data.list[i].transfer_state = this.language.Completed;
            }

            for (let j = 0; j < payment.Paymentmethod.length; j++) {
              if (res.data.list[i].transfer_from_id == payment.Paymentmethod[j].value) {
                res.data.list[i].transfer_from_id = payment.Paymentmethod[j].label;
              }
            }
            if (res.data.list[i].transfer_from_id == 0) {
              res.data.list[i].transfer_from_id = this.language.Alipay;
            } else if (res.data.list[i].transfer_from_id == 1) {
              res.data.list[i].transfer_from_id = this.language.WeChat;
            } else if (res.data.list[i].transfer_from_id == -1) {
              res.data.list[i].transfer_from_id = "";
            }

            res.data.list[i].money =
              res.data.list[i].money + " " + res.data.list[i].currency;

            if (res.data.list[i].pay_server_time) {
              res.data.list[i].pay_server_time = this.$getNowFormatDate.chagetime(
                res.data.list[i].pay_server_time
              );
            }
          }

          //
          this.json_table = [...this.json_table, ...res.data.list];
          if (this.Exportpage == this.Exportpageing) {
            this.ordername = "Order" + this.$getNowFormatDate.getNowtime(new Date());
            this.$nextTick(() => {
              this.Exporttype = 3;
            });
          }
        }
      });
    },
    //搜索
    SearchBtn() {
      document.getElementById("SearchBtn").blur();
      this.Exporttype = 1;
      this.page = 1;
      this.pages = 1;
      if (this.create_time) {
        this.searchtime =
          this.$getNowFormatDate.getNowFormatDate(this.create_time[0]) +
          " 00:00:00 - " +
          this.$getNowFormatDate.getNowFormatDate(this.create_time[1]) +
          " 23:59:59";
      }
      this.init();
    },
    //重置
    ResetBtn() {
      document.getElementById("ResetBtn").blur();
      this.Cashwithdrawalstatus = "";
      this.Currency = "";
      this.EPAYOrder_Number = "";
      this.page = 1;
      this.pages = 1;
      this.Exporttype = 1;
      this.appid = "";
      this.create_time = null;
      this.Order_Number = "";
      this.init();
    },
    // 切换每页条数
    truelimit() {
      this.page = 1;
      this.limit = this.showlimit;
      this.init();
    },
    handleSizeChange(val) {
      this.page = 1;
      this.limit = val;
      this.init();
    },
    //选择页数
    handleCurrentChange(val) {
      this.page = val;
      this.init();
    },
    // 切换语言
    changelanguage() {
      this.Exporttype = 1;
      this.language = this.$units();
      this.options2[0].type_name = this.language.whole;
      this.languagetype = this.$store.state.language;
      this.title = this.language.lookover;
      this.options3[0].label = this.language.whole; //全部

      // if (this.userinfo.currency == "all") {
      //   this.options2[0].type_name = this.language.whole; //全部
      // }

      this.options3[1].label = this.language.Tobereviewed; //待审核
      this.options3[2].label = this.language.Approvedpendingtransfer; //已审核待转款
      this.options3[3].label = this.language.Rejected; //已拒绝
      this.options3[4].label = this.language.Completed; //已完成
      this.json_fields = {
        [this.language.Cashorder]: {
          field: "mch_order_no",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.MerchantID]: {
          field: "mch_id",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.Apply_No + "/appId"]: {
          field: "appid",
          callback: (value) => {
            return "&nbsp;" + value;
          },
        },
        [this.language.name]: "account_name",
        ["USDT" + this.language.Addressdepositbank]: "account_no",
        [this.language.TransactionChannel]: "transfer_from_id",
        [this.language.Currency]: "currency",
        [this.language.Cashwithdrawalamount]: "money",
        [this.language.Applicationtime]: "create_time",
        [this.language.Transfertime]: {
          field: "transfer_server_time",
          callback: (value) => {
            if (value) {
              return "&nbsp;" + this.$getNowFormatDate.chagetime(value);
            }
            return "&nbsp;" + value;
          },
        },
        [this.language.State]: "transfer_state",
      };
    },
  },
};
</script>
<style scoped>
.order_page {
  min-height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.order_page > div {
  min-height: calc(100vh - 129px);
}

.page_title {
  font-size: 26px;
  font-weight: 600;
}

.input_box {
  margin-right: 30px;
}

.input_box3 {
  width: 200px;
}

.paging {
  width: 100%;
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;
}
.msgbox {
  display: flex;
  align-items: center;
}
.msgbox:not(:first-child) {
  margin-top: 20px;
}

* /deep/ .el-table .cell {
  white-space: nowrap;
}

.Export_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialogVisible_center {
  width: 80%;
  margin: 0 auto;
  align-items: center;
}
.search_last_box {
  margin-top: 10px;
  display: flex;
  /* padding: 10px 10px 0 10px; */
  box-sizing: border-box;
  background-color: rgba(242, 242, 242, 1);
}

.search_last_box > div {
  /* width: 300px; */
  /* background-color: rgba(242, 242, 242, 1); */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.search_last_box > div:last-child {
  margin-left: 20px;
}

.search_last_box > div > div:first-child {
  font-family: cursive;
  font-weight: 700;
  color: #333333;
}

.search_last_box > div > div:last-child {
  /* margin-top: 6px; */
  font-weight: 700;
  font-family: cursive;
  margin-left: 10px;
  color: #ff5722;
}

.mb_input_box {
  border: 1px solid #efefef;
  padding: 10px;
}

.input_box2 {
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}

.input_box2 > div:last-child {
  flex: 1;
}

.input_box2 > div:first-child {
  margin-right: 20px;
}
</style>
